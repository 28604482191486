/* .dashboardReferSection {
    background-image: repeating-linear-gradient(264deg, rgba(255,255,255, 0.08) 0px, rgba(255,255,255, 0.08) 1px,transparent 1px, transparent 9px),repeating-linear-gradient(174deg, rgba(255,255,255, 0.08) 0px, rgba(255,255,255, 0.08) 1px,transparent 1px, transparent 9px),linear-gradient(219deg, rgb(0,255,233),rgb(14,14,14));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 20px 35px;
    padding: 10px 0;
}

.dashboardReferSection h1 {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
}

@media (max-width:400px) {
    .dashboardReferSection h1 {
        font-size: 30px;
        text-align: center;
    }
}

@media (max-width:500px) {
    .dashboardReferSection h1 {
        font-size: 40px;
        text-align: center;
    }
} */

.dashboardReferSection {
    background-image: repeating-linear-gradient(264deg, rgba(255, 255, 255, 0.08) 0px, rgba(255, 255, 255, 0.08) 1px, transparent 1px, transparent 9px),
        repeating-linear-gradient(174deg, rgba(255, 255, 255, 0.08) 0px, rgba(255, 255, 255, 0.08) 1px, transparent 1px, transparent 9px),
        linear-gradient(219deg, rgb(0, 255, 233), rgb(14, 14, 14));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 20px 35px;
    padding: 20px 10px;
    /* Increased padding */
}

/* Referral Section Title */
.dashboardReferSection h1 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    text-align: center;
}
.dashboardReferSectionDataCntnr{
    display: flex;
    align-items: center;
    gap: 100px;
}
/* Referral Link Styling */
#headerLink1 {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    width: 100%;
    word-break: break-all;
    /* Ensure the text breaks if it's too long */
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
}

@media (max-width: 1200px) {
    .dashboardReferSection h1 {
        font-size: 22px;
    }

    #headerLink1 {
        font-size: 15px;
    }
}

@media (max-width: 992px) {
    .dashboardReferSection {
        margin: 20px 20px;
        padding: 15px 5px;
    }

    .dashboardReferSection h1 {
        font-size: 20px;
    }

    #headerLink1 {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 768px) {
    .dashboardReferSection {
        flex-direction: column;
        margin: 15px;
        padding: 15px 5px;
    }
.dashboardReferSectionDataCntnr{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 10px;
    width: 100%;
}
    .dashboardReferSection h1 {
        font-size: 18px;
    }

    #headerLink1 {
        font-size: 13px;
        padding: 7px;
    }
}

@media (max-width: 576px) {
    .dashboardReferSection {
        margin: 10px;
        padding: 10px;
    }

    .dashboardReferSection h1 {
        font-size: 16px;
    }

    #headerLink1 {
        font-size: 12px;
        padding: 6px;
    }
}

@media (max-width: 400px) {
    .dashboardReferSection h1 {
        font-size: 14px;
    }

    #headerLink1 {
        font-size: 11px;
        padding: 5px;
    }
}