:root {
    --colorPrimary: #00FFFF;
    --bodyColor: #0f0f0f;
    --brightGreen: rgb(93 255 98);
    /* --containerColor: #1D1D1D; */
    --containerColor: linear-gradient(45deg, #1d1d1d88, #1d1d1d);
    --containerBorder: 1px solid #1d1d1d;
    --textColor: rgb(255, 255, 255);
    --textHeading: white;
    --sideActiveColor: white;
    --borderColor: #fff;
    --lightColor: #252525;
    --darkLightText: #fff;
    --darkLightBackground: #181818;
    --activeTextColor: white;
    --rewardCardActive: #73ba3f2b;
    --rewardCardInactive: #72ba3f0c;
    --btnBackground: linear-gradient(90deg,
            #96792d,
            #c5a454,
            #f8e895,
            #d9bb6e,
            #f3d67f,
            #a88d46);
}

.btnConnect {
    color: black;
    font-weight: 700;
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 16px;
    border: none;
    text-transform: uppercase;
    margin: 10px 0px;
    background: linear-gradient(to right,
            #96792d,
            #c5a454,
            #f8e895,
            #d9bb6e,
            #f3d67f,
            #a88d46);
    border: 1px solid transparent;
    transition: background 0.4s;
}

.pt-28 {
    padding-top: 7rem !important;
}

.btnConnect:hover {
    border-color: var(--colorPrimary);
    color: var(--colorPrimary);
    background: transparent;
}

#connectButtonAddress {
    color: #f6e592;
    font-size: 14px;
    border: 1px solid #f6e592;
    padding: 8px 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}

#connectButtonAddress i {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}