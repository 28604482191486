.rd3t-tree-container>svg>g>path {
  fill: "none";
  stroke: white;
  stroke-width: 2;
}

.rd3t-label__attributes {
  fill: white !important;
}

.rd3t-leaf-node {
  stroke: white !important;
}

.rd3t-node {
  fill: var(--colorPrimary) !important;
}