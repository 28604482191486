.historyhead::after {
  content: "";
  position: absolute;
  display: block;
  background: url(./../../images/border-frame.svg) no-repeat;
  top: 4%;
  left: 49%;
  height: 587px;
  width: 347px;
  -webkit-transform: translateX(-50%) rotate(270deg);
  -ms-transform: translateX(-50%) rotate(270deg);
  transform: translateX(-50%) rotate(270deg);
  -webkit-filter: drop-shadow(0px 0px 6px #006f76) drop-shadow(0px 0px 6px #006f76);
  filter: drop-shadow(0px 0px 6px #006f76) drop-shadow(0px 0px 6px #006f76);
  z-index: -1;
}

/* ////////////////////////// */
.TableData{
  border-color:#fbfbfb30;
  white-space: nowrap;
}
.status-pending {
  color: rgb(226, 197, 30) !important;
}

.status-success {
  color: green !important;
}

.status-rejected {
  color: red !important;
}



.select-container {
  display: flex;
  margin-bottom: 7px;
}

.select-element {
  width: 100%;
  padding: 10px;
  background-color: rgba(209, 213, 219, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 12px;
  /* Adjust as needed */
  line-height: 1.75;
  outline: none;
  border: none;
}

.select-element option {
  background-color: #151616;
  /* Match select background */
  color: #01ffff;
}

/* Additional styling for focus state (optional) */
.select-element:focus {
  box-shadow: none;
  /* Define other focus styles as needed */
}

/* //////////////////////////////// */

.historyhead::before {
  content: "";
  position: absolute;
  display: block;
  background: url(./../../images/border-frame.svg) no-repeat;
  top: -30%;
  left: 52%;
  height: 587px;
  width: 347px;
  -webkit-transform: translateX(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) rotate(90deg);
  transform: translateX(-50%) rotate(90deg);
  -webkit-filter: drop-shadow(0px 0px 6px #006f76) drop-shadow(0px 0px 6px #006f76);
  filter: drop-shadow(0px 0px 6px #006f76) drop-shadow(0px 0px 6px #006f76);
  z-index: -1;
  background-size: cover;
}

@media (max-width: 640px) {
  .historyhead::before {
    display: none;
    background: none !important;
  }

  .historyhead::after {
    display: none;
    background: none !important;
  }
}