/* src/App.css */
.popup {
  position: fixed;
    bottom: 95px;
    width: auto;
    height: fit-content;
    background: #000000c7;
    display: block;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    margin: 0px;
}

.popup-inner {
  background: transparent;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
