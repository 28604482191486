/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #181a1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2c3232;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.submenu .child-menu {
    max-height: 0;
    overflow: hidden;
}

.submenu input:checked ~ .child-menu {
    max-height: 500px;
}