body {
    margin: 0;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-tap-highlight-color: transparent;
}

.container {
    width: 100%;
}

.detailCardDiv {
    gap: 30px;
}
.detailCard {
    width: auto;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (max-width: 425px) {
    .detailCard {
        width: 260px ;
    }

    .detailCardDiv {
        gap: 15px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

/* #myTabContent{
    margin-top: 30px ;
} */
.TableData {
    border-color: #fbfbfb30
}

#TableOrdrId {
    color: #08FBFF !important
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.hidden {
    display: none;
}

.h-12 {
    height: 3rem;
}

.h-full {
    height: 100%;
}

.w-12 {
    width: 3rem;
}

.w-full {
    width: 100%;
}

.text-center {
    text-align: center;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    box-shadow: var(--tw-shadow);
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.recharts-legend-wrapper {
    position: absolute;
    width: 100% !important;
    height: auto;
    left: 0px !important;
    bottom: 5px;
}

.duration-500 {
    transition-duration: 500ms;
}

.chartboxDash {
    display: flex;
    justify-content: center;
}